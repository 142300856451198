<template>
<div>
    <bookingSuccess />
</div>
</template>

<script>
import bookingSuccess from '../../components/dashboard/booking/BookingSuccess'
export default {
    components: {
        bookingSuccess
    }
}
</script>

<style>
