<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
          <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
          </div>-->
     
      <div class="mt-3" v-if="windowWidth >= 992" style="padding:0% 2%">

   <div class="display-div mb-2 point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; max-width:300px;"   v-if="windowWidth <= 1270 && windowWidth >= 770" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"  @click="copyRefCode($event)">
                                        <div >

                                            <img src="../../../assets/images/speaker.png"  height="20">

                                         </div>
                                         <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                                                <div class="text-right">

                                                    <img src="../../../assets/images/info.png"  height="20"  title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>


                    <div class="display-div" style="justify-content:space-between; align-items:center" v-if="windowWidth > 769">

                        <div  style=" flex-basis:30%" :class="{'flex-22': windowWidth < 1614}" >
                            <button style="border-radius:5px; min-height:40px; background:#fff; border:1px solid #fff" @click="sendToBookAmount">
 <img src="../../../assets/images/bookcol3.png" class="mr-2" height="20" >
                Book new amount
            </button>


                                <!-- <i class="fa fa-chevron-down" ></i> -->
                            </div>

                                <div style="flex-basis:40%; display:flex; justify-content:center" v-if="windowWidth > 1270" :class="{'flex-36': windowWidth < 1614}">

                                    <div  style="max-width:300px">
                                    <div class="display-div point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; padding: 0px 10px" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"   @click="copyRefCode($event)">
                                        <div>

                                            <img src="../../../assets/images/speaker.png"  height="20">

                                         </div>

                                           <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                               
                                                <div class="text-right">

                                                    <img src="../../../assets/images/info.png"  height="20" title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>
                                                </div>
                                            </div>
                                               <div style="flex-basis:30%; display:flex; justify-content:flex-end" :class="{'flex-38': windowWidth < 1614}">
                                            <div>
                                                <div style="display:flex;border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center;  padding: 5px 10px">
                                                    <div>

                                                     <img src="../../../assets/images/bookIcon3.png"  height="20">

              </div>
                                                          <div>
                      <p
                        style="color: #828282; font-size:12px"
                        class="text-right"
                      >Current booking total</p>

                      <h5 class="text-right">
                      <span>&#8358;</span> {{formatAmount(totalBookedValue)}}
                    </h5>
                    </div>
                                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                      <div >
                                                <div class="ml-2" style="display:flex;border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center; padding: 5px 10px">
                                                    <div>

                                                     <img src="../../../assets/images/info.png"  height="20">

              </div>
                                                        <div>
                      <p
                        style="color: #828282; font-size:12px"
                        class="text-right"
                      >Current accrued interest</p>

            <h5 class="text-right">
                      <span>&#8358;</span> {{formatAmount(totalBookedAccruedInterestValue)}}
                    </h5>
                      <!-- <p class="text-right">retrieving value...</p> -->
                    </div>
                                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                                        </div>
                                                        <div>
</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>
       

          <div class="book-home-cover">
            <div class="row">
              <div class="col-md-3">
               
              </div>

              <div class="col-md-6 mt-4">
                <div class="booking-card">
                  <div style="position:relative;" class="text-center">
                    <img src="../../../assets/images/booksuccess.png" height="80">
                  </div>
                  <div class="display-div text-center mb-3 mt-3">
                    <p style=" color:#828282; font-size:18px" class="mr-3" ><span>&#8358;</span>{{formatAmount(fundAmount)}} has been added to your booking list</p>
                  </div>

                  <hr>
                  <!-- <div
                    style="display:flex; min-height:50px;align-items:center; justify-content:center"
                    class="point-cursor" @click="sendToBookHistory"
                  >
                    <p style="color:#0094FF; font-size:18px" class="mr-3">See booking list</p>
                  </div> -->

                   <div class="booking-card mt-2 mb-2 point-cursor" @click="sendToBookHistory" style="display:flex; justify-content:center; align-items:center">
<p style="color:#0094FF; font-size:18px">See booking list</p>  <img src="../../../assets/images/rightarrowb.png" class="ml-4"  height="15">
</div>
                </div>
            

  
              </div>
        
            </div>
          </div>


      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
      fundAmount:''
    };
  },
  methods: {
    sendToBookAmount() {
      this.$router.push("/book/amount");
    },
    sendToBookHistory() {
      this.$router.push("/book/history");
    },
   
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
     
    }),

    
  },
  mounted() {
    let heading = {
      main: this.tran.booking
    };
        this.$store.dispatch("market/getBookingHistory");
            this.$store.dispatch("market/getBookFundsSummary");
    this.$store.commit("market/savePageHeading", heading);
this.fundAmount = this.$route.query.fundAmount
   this.$store.commit(
      "auth/saveBookRouteVisited",
     "/book"
    );

if(this.$route.query.fundAmount == undefined){

  this.$router.go(-1  )
}
  },


};
</script>

<style scoped>
.booking-card {
  background: #fff;
  min-height: 50px;
  border-radius: 10px;
  padding: 10px;

  overflow: hidden !important;
}
.book-home-cover {
  padding: 5%;
  min-height: 40vh;
  display: flex;
  align-items: center;
}

@media(max-width:767px){

  .book-home-cover {

    padding: 0% !important;

}
}
</style>
